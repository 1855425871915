export const environment = {
	production: true,
	demo: false,
	serviceUrl: 'https://api.sportsvision.io/admin',
	S3_Video_Out_BaseUrl__Sufc:'https://d92nllyaky22a.cloudfront.net',
	S3_Video_Out_BaseUrl_automate: 'https://streaming.plnt.tv',
	S3_Video_Out_BaseUrl_old: 'https://d2ogd7wzpm4vl4.cloudfront.net',
	S3_Video_In_BaseUrl: "https://sw-video-in.s3.eu-west-1.amazonaws.com/",
	S3_WATERMARK_IMAGE_URL: "https://sw-video-out.s3.amazonaws.com/express/watermark/",
};
